import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Space,
  Switch,
  Typography,
} from "antd";
import "katex/dist/katex.min.css";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { showError, showSuccess } from "../../../toastService";
import { renderMath } from "../../../utils/renderMath";

const { TextArea } = Input;
const { Text, Title } = Typography;

interface Props {
    resetMode:() => void,
    unitId : any
}

const MatchTheColumnComponent: React.FC<Props> = ({resetMode,unitId}) => {
  const [form] = Form.useForm();
  const [questionPreview, setQuestionPreview] = useState<string>("");
  const [options, setOptions] = useState<string[]>(["", "", "", ""]);
  const [optionPreviews, setOptionPreviews] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [optionTypes, setOptionTypes] = useState<string[]>([
    "text",
    "text",
    "text",
    "text",
  ]);
  const [correctOption, setCorrectOption] = useState<number | null>(null);
  const [explanations, setExplanations] = useState<string[]>(["", "", "", ""]);
  const [explanationPreview, setExplanationPreview] = useState<string>("");
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<"view" | "add">("view");
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(
    "easy"
  );

  useEffect(() => {
    if (viewMode === "add") {
      form.setFieldsValue({
        question: questionPreview,
        options: options,
      });
    }
  }, [viewMode, questionPreview, options]);

  const addImageUrl = () => {
    setImageUrls([...imageUrls, ""]);
  };

  const updateImageUrl = (index: number, value: string) => {
    const newImageUrls = [...imageUrls];
    newImageUrls[index] = value;
    setImageUrls(newImageUrls);
  };

  const addOption = () => {
    setOptions([...options, ""]);
    setOptionPreviews([...optionPreviews, ""]);
    setExplanations([...explanations, ""]);
    setOptionTypes([...optionTypes, "text"]);
  };

  const handleExplanationChange = (index: number, value: string) => {
    const newExplanations = [...explanations];
    newExplanations[index] = value;
    setExplanations(newExplanations);
    if (correctOption === index) {
      setExplanationPreview(value);
    }
  };

  const toggleOptionType = (index: number) => {
    const newOptionTypes = [...optionTypes];
    newOptionTypes[index] = newOptionTypes[index] === "text" ? "image" : "text";
    setOptionTypes(newOptionTypes);
  };

  const handleSubmit = (values: any) => {
    if (correctOption == null) {
      return showError("Please select a correct option!");
    }
    const questionData = {
      question: values.question,
      images: imageUrls.filter((url) => url.trim() !== ""),
      options,
      optionTypes,
      correctOption,
      explanations,
      difficulty,
      type: "singleselect",
    };
    form.resetFields();
    handleSave(questionData);
  };

  const handleSave = async (questionData: any) => {
    try {
      await axiosInstance.post(
        `/api/admin/v1/unit/create-qeustion/matchthecolumn/${unitId}`,
        questionData
      );
      console.log("questioData", questionData);
      showSuccess("Question added successfully!");
      setOptions(["", "", "", ""]);
      setOptionPreviews(["", "", "", ""]);
      setOptionTypes(["text", "text", "text", "text"]);
      setCorrectOption(null);
      setExplanations(["", "", "", ""]);
      setExplanationPreview("");
      setImageUrls([]);
      setQuestionPreview("");
      setViewMode("view");
      resetMode();
    } catch (error) {
      showError("Failed to save lecture details. Please try again.");
    }
  };

  return (
    <div style={{ maxWidth: 900 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Difficulty Level"
          name="difficulty"
          rules={[
            { required: true, message: "Please enter the difficulty level!" },
          ]}
        >
          <Radio.Group
            onChange={(e) => setDifficulty(e.target.value)}
            value={difficulty}
          >
            <Radio value="easy">Easy</Radio>
            <Radio value="medium">Medium</Radio>
            <Radio value="hard">Hard</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Question Text (Supports LaTeX)"
          name="question"
          rules={[
            { required: true, message: "Please enter the question text!" },
          ]}
        >
          <TextArea
            rows={4}
            onChange={(e) => setQuestionPreview(e.target.value)}
            placeholder="Enter question text with or without LaTeX syntax"
          />
        </Form.Item>

        <Divider />
        <Text strong>LaTeX Preview:</Text>
        <div
          style={{ minHeight: 50, padding: "10px 0" }}
          dangerouslySetInnerHTML={{
            __html: renderMath(questionPreview) || "Type to see preview...",
          }}
        />
        <Divider />

        <Title level={5}>Image URLs</Title>
        {imageUrls.map((url, index) => (
          <Space key={index} style={{ marginBottom: 8 }} align="baseline">
            <Input
              placeholder={`Image URL ${index + 1}`}
              value={url}
              onChange={(e) => updateImageUrl(index, e.target.value)}
            />
          </Space>
        ))}
        <Button type="dashed" onClick={addImageUrl}>
          Add Image URL
        </Button>
        <Divider />

        <Title level={5}>Options (Supports LaTeX)</Title>
        {options.map((option, index) => (
          <Space key={index} style={{ marginBottom: 8 }} align="baseline">
            <Switch
              checked={optionTypes[index] === "image"}
              onChange={() => toggleOptionType(index)}
              checkedChildren="Image"
              unCheckedChildren="Text"
            />
            {optionTypes[index] === "text" ? (
              <Input
                required
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => {
                  const newOptions = [...options];
                  const newOptionPreviews = [...optionPreviews];
                  newOptions[index] = e.target.value;
                  newOptionPreviews[index] = e.target.value;
                  setOptions(newOptions);
                  setOptionPreviews(newOptionPreviews);
                }}
              />
            ) : (
              <Input
                required
                placeholder={`Image URL for Option ${index + 1}`}
                value={option}
                onChange={(e) => {
                  const newOptions = [...options];
                  const newOptionPreviews = [...optionPreviews];
                  newOptions[index] = e.target.value;
                  newOptionPreviews[index] = e.target.value;
                  setOptions(newOptions);
                  setOptionPreviews(newOptionPreviews);
                }}
              />
            )}
            <Radio
              checked={correctOption === index}
              onChange={() => {
                setCorrectOption(index);
                setExplanationPreview(explanations[index]);
              }}
            >
              Correct
            </Radio>
          </Space>
        ))}

        {correctOption !== null && (
          <Form.Item label="Explanation for Correct Option">
            <TextArea
              rows={2}
              value={explanations[correctOption] || ""}
              onChange={(e) =>
                handleExplanationChange(correctOption, e.target.value)
              }
              placeholder="Explanation for the selected correct option"
            />
            <Divider />
            <Text strong>Explanation Preview:</Text>
            <div
              style={{ minHeight: 50, padding: "10px 0" }}
              dangerouslySetInnerHTML={{
                __html:
                  renderMath(explanationPreview) || "Type to see preview...",
              }}
            />
            <Divider />
          </Form.Item>
        )}

        <Divider />
        <Text strong>Options Preview:</Text>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {optionPreviews.map((preview, index) => (
            <li
              key={index}
              style={{
                backgroundColor:
                  correctOption === index ? "#d4edda" : "#f8f9fa",
                padding: "8px 12px",
                marginBottom: "8px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
              }}
            >
              {optionTypes[index] === "text" ? (
                <div
                  dangerouslySetInnerHTML={{ __html: renderMath(preview) }}
                />
              ) : (
                <img
                  src={preview}
                  alt={`Option ${index + 1}`}
                  style={{ maxWidth: 200, maxHeight: 200 }}
                />
              )}
            </li>
          ))}
        </ul>

        <Button type="dashed" onClick={addOption} style={{ marginBottom: 20 }}>
          Add Option
        </Button>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MatchTheColumnComponent;
