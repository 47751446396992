import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../axiosInstance";
import UnitLecturesCollapse from "../../../../components/subjects/UnitLectureCollapse";
import UnitQuestionEntry from "../../../../components/unit/UnitQuestionEntry/UnitQuestionEntry";
import { showError, showSuccess } from "../../../../toastService";

const { Panel } = Collapse;

const SubjectDetails = () => {
  const [units, setUnits] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUnit, setEditingUnit] = useState<any>(null); // Holds data for editing
  const { id: subjectId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchUsnit();
    setIsActive(localStorage.getItem("live") === "true");
  }, [subjectId, navigate]);

  const fetchUsnit = () => {
    axiosInstance
      .get(`/api/admin/v1/unit/${subjectId}`)
      .then((res: any) => {
        setUnits(res.data.units || []);
      })
      .catch((error: any) => {
        showError("An unexpected error occurred. Please try again later.");
        localStorage.clear();
        navigate("/");
      });
  };

  // Open modal for Add or Edit
  const openModal = (unit: any = null) => {
    setEditingUnit(unit);
    setIsModalOpen(true);
    if (unit) {
      form.setFieldsValue({ name: unit.name, description: unit.description });
    } else {
      form.resetFields();
    }
  };

  // Handle Add or Edit Submission
  const handleFormSubmit = async (values: any) => {
    const payload = {
      subjectId,
      unitInfo: { ...values },
    };

    if (editingUnit) {
      console.log(editingUnit);
      var editPayload = { ...payload, unitId: editingUnit.id };
      // Edit Unit
      axiosInstance
        .put(`/api/admin/v1/unit/update`, editPayload)
        .then(() => {
          showSuccess("Unit updated successfully!");
          setUnits((prevUnits) =>
            prevUnits.map((unit) =>
              unit.id === editingUnit.id ? { ...unit, ...values } : unit
            )
          );
          setIsModalOpen(false);
        })
        .catch(() => showError("Failed to update unit. Please try again."));
    } else {
      axiosInstance
        .post(`/api/admin/v1/unit/create`, payload)
        .then((res: any) => {
          showSuccess("Unit added successfully!");
          fetchUsnit();
          setIsModalOpen(false);
        })
        .catch(() => showError("Failed to add unit. Please try again."));
    }
  };

  // Delete a unit
  const handleDelete = (unitId: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this unit?",
      content: "This action cannot be undone.",
      onOk: () => {
        axiosInstance
          .delete(`/api/admin/v1/unit/${unitId}`)
          .then(() => {
            showSuccess("Unit deleted successfully!");
            setUnits((prevUnits) =>
              prevUnits.filter((unit) => unit.id !== unitId)
            );
          })
          .catch(() => showError("Failed to delete unit. Please try again."));
      },
      onCancel() {
        // No action needed on cancel
      },
    });
  };

  // Custom header with actions
  const renderHeader = (unit: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{unit.name}</span>
      <Space>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => openModal(unit)}
            style={{ color: "#1890ff", cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip
          title={isActive ? "Cannot delete live subject unit" : "Delete"}
        >
          {isActive ? (
            <DeleteOutlined style={{ color: "gray", cursor: "not-allowed" }} />
          ) : (
            <DeleteOutlined
              onClick={() => handleDelete(unit.id)}
              style={{ color: "#ff4d4f", cursor: "pointer" }}
            />
          )}
        </Tooltip>
      </Space>
    </div>
  );

  return (
    <div className="subject-details-container">
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/dashboard/subjects");
        }}
      >
        <LeftOutlined /> Back
      </div>
      <div>
        <Title level={3}>
          {localStorage.getItem("subName") ?? "Subject Name"}
        </Title>
      </div>
      <div style={{ color: "gray", marginBottom: "20px" }}>
        {localStorage.getItem("subDesc") ?? "Subject Description"}
      </div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginBottom: "10px" }}
        onClick={() => openModal()}
        disabled={isActive}
      >
        Add Unit
      </Button>
      <Collapse accordion>
        {units.map((unit: any) => (
          <Panel header={renderHeader(unit)} key={unit.id}>
            <Tabs>
              <TabPane tab="Description" key="1">
                <p>{unit.description}</p>
              </TabPane>
              <TabPane tab="Questions" key="2">
                <UnitQuestionEntry unitId={unit?.id} />
              </TabPane>
            </Tabs>
            <UnitLecturesCollapse unit={unit} live={isActive} />
          </Panel>
        ))}
      </Collapse>

      {/* Add/Edit Modal */}
      <Modal
        title={editingUnit ? "Edit Unit" : "Add Unit"}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            label="Unit Name"
            name="name"
            rules={[{ required: true, message: "Please enter the unit name!" }]}
          >
            <Input placeholder="Enter unit name" />
          </Form.Item>
          <Form.Item
            label="Unit Description"
            name="description"
            rules={[
              { required: true, message: "Please enter the unit description!" },
            ]}
          >
            <Input.TextArea placeholder="Enter unit description" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SubjectDetails;
