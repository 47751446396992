import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import React, { useState } from "react";
import FillInTheBlankComponent from "./QuestionTypesComponent/FillInTheBlankComponent";
import MatchTheColumnComponent from "./QuestionTypesComponent/MatchTheColumnComponent";
import MultiSelectComponent from "./QuestionTypesComponent/MultiSelectComponent";
import NumericalTypeComponent from "./QuestionTypesComponent/NumericalTypeComponent";
import SingleSelectComponent from "./QuestionTypesComponent/SingleSelectComponent";
import TrueOrFalseComponent from "./QuestionTypesComponent/TrueOrFalseComponent";

interface Props {
  unitId : any
}

// Define a type for the question types
type QuestionType =
  | "singleChoice"
  | "multipleChoice"
  | "trueFalse"
  | "numerical"
  | "fillInTheBlank"
  | "matchTheColumn";

const AddQuestion : React.FC<Props> = ({unitId}) => {
  // State to track the selected question type
  const [selectedQuestionType, setSelectedQuestionType] =
    useState<QuestionType | null>("singleChoice");

  // Handler for menu item clicks
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setSelectedQuestionType(e.key as QuestionType);
  };

  // Menu items for the dropdown
  const items = [
    { key: "singleChoice", label: "Single Choice" },
    { key: "multipleChoice", label: "Multiple Choice" },
    { key: "trueFalse", label: "True/False" },
    { key: "numerical", label: "Numerical" },
    { key: "fillInTheBlank", label: "Fill In the Blank" },
    { key: "matchTheColumn", label: "Match the Column" },
  ];

  // Find the selected label
  const selectedLabel =
    items?.find((item) => item?.key === selectedQuestionType)?.label ||
    "Select Question Type";

  // Render different components based on the selected question type
  const renderQuestionComponent = () => {
    switch (selectedQuestionType) {
      case "singleChoice":
        return (
          <div>
            <SingleSelectComponent unitId={unitId} resetMode={resetMode} />
          </div>
        );
      case "multipleChoice":
        return (
          <div>
            <MultiSelectComponent unitId={unitId} resetMode={resetMode} />
          </div>
        );
      case "trueFalse":
        return <div><TrueOrFalseComponent resetMode={resetMode} unitId={unitId} /></div>;
      case "numerical":
        return <div><NumericalTypeComponent unitId={unitId} resetMode={resetMode} /></div>;
      case "fillInTheBlank":
        return <div><FillInTheBlankComponent resetMode={resetMode} unitId={unitId} /></div>;
      case "matchTheColumn":
        return <div><MatchTheColumnComponent resetMode={resetMode} unitId={unitId} /></div>;
      default:
        return <div>Please select a question type</div>;
    }
  };

  const resetMode = () => {
    setSelectedQuestionType(null);
  };

  return (
    <div>
      <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]}>
        <Button>
          {selectedLabel} <DownOutlined />
        </Button>
      </Dropdown>
      {/* Render the selected question component */}
      <div style={{ marginTop: 20 }}>{renderQuestionComponent()}</div>
    </div>
  );
};

export default AddQuestion;
