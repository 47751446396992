import { Button, Divider, Form, Input, Radio, Space, Typography } from "antd";
import "katex/dist/katex.min.css";
import React, { useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { showError, showSuccess } from "../../../toastService";
import { renderMath } from "../../../utils/renderMath";

const { TextArea } = Input;
const { Text, Title } = Typography;

interface Props {
  resetMode: () => void;
  unitId:any;
}

const TrueOrFalseComponent: React.FC<Props> = ({ resetMode,unitId }) => {
  const [form] = Form.useForm();
  const [questionPreview, setQuestionPreview] = useState<string>("");
  const [correctOption, setCorrectOption] = useState<boolean | null>(null);
  const [explanation, setExplanation] = useState<string>("");
  const [explanationPreview, setExplanationPreview] = useState<string>("");
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(
    "easy"
  );
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const handleSubmit = (values: any) => {
    if (correctOption == null) {
      return showError("Please select the correct answer!");
    }
    const questionData = {
      question: values.question,
      imageUrls,
      correctAnswer : correctOption,
      explanation,
      difficulty,
      type: "truefalse",
    };
    form.resetFields();
    handleSave(questionData);
  };

  const handleSave = async (questionData: any) => {
    try {
      await axiosInstance.post(
        `/api/admin/v1/unit/create-qeustion/truefalse/${unitId}`,
        questionData
      );
      console.log("questionData", questionData);
      showSuccess("Question added successfully!");
      setCorrectOption(null);
      setExplanation("");
      setExplanationPreview("");
      setQuestionPreview("");
      resetMode();
    } catch (error) {
      showError("Failed to save question. Please try again.");
    }
  };

  const updateImageUrl = (index: number, value: string) => {
    const newImageUrls = [...imageUrls];
    newImageUrls[index] = value;
    setImageUrls(newImageUrls);
  };

  const addImageUrl = () => {
    setImageUrls([...imageUrls, ""]);
  };

  return (
    <div style={{ maxWidth: 900 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Difficulty Level"
          name="difficulty"
          rules={[
            { required: true, message: "Please select difficulty level!" },
          ]}
        >
          <Radio.Group
            onChange={(e) => setDifficulty(e.target.value)}
            value={difficulty}
          >
            <Radio value="easy">Easy</Radio>
            <Radio value="medium">Medium</Radio>
            <Radio value="hard">Hard</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Question Text (Supports LaTeX)"
          name="question"
          rules={[
            { required: true, message: "Please enter the question text!" },
          ]}
        >
          <TextArea
            rows={4}
            onChange={(e) => setQuestionPreview(e.target.value)}
            placeholder="Enter question text with or without LaTeX syntax"
          />
        </Form.Item>

        <Divider />
        <Text strong>LaTeX Preview:</Text>
        <div
          style={{ minHeight: 50, padding: "10px 0" }}
          dangerouslySetInnerHTML={{
            __html: renderMath(questionPreview) || "Type to see preview...",
          }}
        />
        <Divider />

        <Title level={5}>Image URLs</Title>
        {imageUrls.map((url, index) => (
          <Space key={index} style={{ marginBottom: 8 }} align="baseline">
            <Input
              placeholder={`Image URL ${index + 1}`}
              value={url}
              onChange={(e) => updateImageUrl(index, e.target.value)}
            />
          </Space>
        ))}
        <Button type="dashed" onClick={addImageUrl}>
          Add Image URL
        </Button>
        <Divider />

        <Title level={5}>Select the Correct Answer</Title>
        <Radio.Group
          onChange={(e) => setCorrectOption(e.target.value)}
          value={correctOption}
        >
          <Space direction="vertical">
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Space>
        </Radio.Group>

        {correctOption !== null && (
          <Form.Item label="Explanation">
            <TextArea
              rows={2}
              value={explanation}
              onChange={(e) => {
                setExplanation(e.target.value);
                setExplanationPreview(e.target.value);
              }}
              placeholder="Enter explanation for the correct answer"
            />
            <Divider />
            <Text strong>Explanation Preview:</Text>
            <div
              style={{ minHeight: 50, padding: "10px 0" }}
              dangerouslySetInnerHTML={{
                __html:
                  renderMath(explanationPreview) || "Type to see preview...",
              }}
            />
            <Divider />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TrueOrFalseComponent;
