import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Modal, Space, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { showError, showSuccess } from "../../toastService";
import LectureTabs from "./LectureTabs";

const { Panel } = Collapse;
const { TabPane } = Tabs;

interface Props {
  unit: any;
  live: boolean;
}

const UnitLecturesCollapse: React.FC<Props> = ({ unit, live }) => {
  const [lectures, setLectures] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingLecture, setEditingLecture] = useState<any>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (unit?.id) {
      fetchLectures(unit.id);
    }
  }, [unit]);

  const fetchLectures = (unitId: any) => {
    axiosInstance
      .get(`/api/admin/v1/lecture/unit/${unitId}`)
      .then((res) => {
        setLectures(res.data.lectures || []);
      })
      .catch(() => {
        console.error("Failed to fetch lectures.");
      });
  };

  const openModal = (lecture: any = null) => {
    setEditingLecture(lecture);
    setIsModalOpen(true);
    if (lecture) {
      form.setFieldsValue({
        name: lecture.name,
        description: lecture.description,
      });
    } else {
      form.resetFields();
    }
  };

  const handleFormSubmit = (values: any) => {
    const payload = {
      unitId: unit.id,
      lectureInfo: { ...values },
    };

    if (editingLecture) {
      const editPayload = { ...payload, lectureId: editingLecture.id };
      axiosInstance
        .put(`/api/admin/v1/lecture/update`, editPayload)
        .then(() => {
          setLectures((prevLectures: any) =>
            prevLectures.map((lecture: any) =>
              lecture.id === editingLecture.id
                ? { ...lecture, ...values }
                : lecture
            )
          );
          setIsModalOpen(false);
        })
        .catch(() => {
          console.error("Failed to update lecture.");
        });
    } else {
      axiosInstance
        .post(`/api/admin/v1/lecture/create`, payload)
        .then(() => {
          setIsModalOpen(false);
          fetchLectures(unit.id);
          showSuccess("Lecture created successfully!");
        })
        .catch(() => {
          showError("Failed to add lecture.");
        });
    }
  };

  const handleDelete = (lecture: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this lecture?",
      content: "This action cannot be undone.",
      onOk: () => {
        axiosInstance
          .delete(`/api/admin/v1/lecture/${lecture.id}`)
          .then(() => {
            showSuccess("Lecture deleted successfully!");
            fetchLectures(unit.id);
          })
          .catch(() =>
            showError("Failed to delete lecture. Please try again.")
          );
      },
      onCancel() {},
    });
  };

  const renderHeader = (lecture: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{lecture.name}</span>
      <Space>
        <Tooltip title="Edit">
          <EditOutlined
            onClick={() => openModal(lecture)}
            style={{ color: "#1890ff", cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteOutlined
            onClick={() => handleDelete(lecture)}
            style={{ color: "#ff4d4f", cursor: "pointer" }}
          />
        </Tooltip>
      </Space>
    </div>
  );

  return (
    <div style={{marginTop : '1rem'}}>
      <Button
        type="primary"
        onClick={() => openModal()}
        style={{ marginBottom: "10px" }}
        disabled={live}
      >
        Add Lecture
      </Button>
      <Collapse accordion>
        {lectures.map((lecture: any) => (
          <Panel header={renderHeader(lecture)} key={lecture.id}>
            <LectureTabs lecture={lecture} />
          </Panel>
        ))}
      </Collapse>
      <Modal
        title={editingLecture ? "Edit Lecture" : "Add Lecture"}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            label="Lecture Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the lecture name!" },
            ]}
          >
            <Input placeholder="Enter lecture name" />
          </Form.Item>
          <Form.Item
            label="Lecture Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter the lecture description!",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter lecture description" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UnitLecturesCollapse;
