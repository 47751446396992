import { Button } from "antd";
import React, { useState } from "react";
import AddQuestion from "../AddQuestion";
import ViewQuestion from "../ViewQuestion";

type ViewType = "view" | "add";

interface Props {
    unitId : any
}

const UnitQuestionEntry : React.FC<Props> = ({unitId}) => {
  const [viewType, setViewType] = useState<ViewType>("view");

  const handleChangeType = () => {
    setViewType((prev) => (prev === "view" ? "add" : "view"));
  };

  return (
    <div>
      <Button type="primary" onClick={handleChangeType}>
        {viewType === "view" ? "Add Question" : "View Question"}
      </Button>
      <br />
      <br />
      {viewType === "view" ? <ViewQuestion unitId={unitId} /> : <AddQuestion unitId={unitId} />}
    </div>
  );
};

export default UnitQuestionEntry;
