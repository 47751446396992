import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Card, List, Tag, Typography } from "antd";
import "katex/dist/katex.min.css";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { renderMath } from "../../utils/renderMath";
const { Text } = Typography;
interface Props {
  unitId: any;
}

const ViewQuestion: React.FC<Props> = ({ unitId }) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [isQuestionVisible, setIsQuestionVisible] = useState<boolean[]>([]); // State for visibility of questions
  useEffect(() => {
    getQuestions();
  }, []);
  const getQuestions = async () => {
    var apires = await axiosInstance.get(
      `/api/admin/v1/unit/view-all-unit-questions/${unitId}`
    );
    setQuestions(apires.data?.questions);
  };
  // Define background colors based on difficulty
  const getDifficultyColor = (difficulty: "easy" | "medium" | "hard") => {
    switch (difficulty) {
      case "hard":
        return "red";
      case "medium":
        return "orange";
      case "easy":
        return "green";
      default:
        return "gray";
    }
  };
  const getType = (type: any) => {
    switch (type) {
      case "matchthecolumn":
        return "Match the column";
      case "fillintheblank":
        return "Fill in the blank";
      case "numerical":
        return "Numerical";
      case "truefalse":
        return "True or false";
      case "multiselect":
        return "Multi-select";
      case "singleselect":
        return "Single Select";
      default:
        return "";
    }
  };
  // Toggle the visibility of a question
  const toggleQuestionVisibility = (index: number) => {
    const updatedVisibility = [...isQuestionVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setIsQuestionVisible(updatedVisibility);
  };
  const handleDelete = async (index: number, question: any) => {
    var question;
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
    const updatedVisibility = isQuestionVisible.filter((_, i) => i !== index);
    setIsQuestionVisible(updatedVisibility);
    // await axiosInstance
    //   .delete(`api/admin/v1/lecture/delete-exercise-question/${question?.id}`)
    //   .then((res) => {
    //     getQuestions();
    //   });
  };
  return (
    <div>
      <List
        grid={{ gutter: 10, column: 2 }}
        dataSource={questions}
        renderItem={(question, index) => (
          <Card key={index} style={{ margin: 10 }}>
            <div
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* Difficulty Chip */}
              <Tag
                color={getDifficultyColor(question.difficulty)}
                style={{ marginBottom: 10, width: "10vw", textAlign: "center" }}
              >
                {question.difficulty.charAt(0).toUpperCase() +
                  question.difficulty.slice(1)}

                {/* Capitalize difficulty */}
              </Tag>
              <Text strong>Question {index + 1}:</Text>
              <Button
                type="text"
                icon={
                  isQuestionVisible[index] ? <UpOutlined /> : <DownOutlined />
                }
                onClick={() => toggleQuestionVisibility(index)}
              />
            </div>
            {isQuestionVisible[index] && (
              <>
                <p
                  style={{
                    padding: "0.5rem",
                    backgroundColor: "#f8edff",
                    width: "max-content",
                    borderRadius : "0.2rem",
                    fontSize : "0.7rem",
                    fontWeight : 500
                  }}
                >
                  {getType(question?.type)}
                </p>
                <div
                  style={{ marginBottom: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: renderMath(question.question),
                  }}
                />
                {question.images.map((url: string, idx: number) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`Question ${index + 1} Image ${idx + 1}`}
                    style={{
                      maxWidth: 200,
                      maxHeight: 200,
                      display: "block",
                      marginBottom: 10,
                    }}
                  />
                ))}
                <Text strong>Options:</Text>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {question.options.map((option: string, optIndex: number) => (
                    <li
                      key={optIndex}
                      style={{
                        backgroundColor:
                          question.type === "multiselect"
                            ? question.multiSelectOptions.includes(optIndex)
                              ? "#d4edda"
                              : "#f8f9fa"
                            : question.type === "numerical" ||
                              question.type === "fillintheblank"
                            ? "#d4edda"
                            : parseInt(question.correctOption) === optIndex
                            ? "#d4edda"
                            : "#f8f9fa",
                        padding: "8px 12px",
                        marginBottom: "8px",
                        borderRadius: "4px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      {question.optionTypes[optIndex] === "text" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: renderMath(option),
                          }}
                        />
                      ) : (
                        <img
                          src={option}
                          alt={`Option ${optIndex + 1}`}
                          style={{ maxWidth: 200, maxHeight: 200 }}
                        />
                      )}
                      <br />
                      {((question.type === "numerical" ||
                        question.type === "fillintheblank") &&
                        question.explanations[0]) ||
                      (question.type === "multiselect"
                        ? question.multiSelectOptions.includes(optIndex) &&
                          question.explanations[optIndex]
                        : parseInt(question.correctOption) === optIndex &&
                          question.explanations[optIndex]) ? (
                        <Text
                          type="secondary"
                          style={{ fontSize: "12px", marginTop: "5px" }}
                        >
                          Explanation:{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: renderMath(
                                question.type === "numerical" ||
                                  question.type === "fillintheblank"
                                  ? question.explanations[0]
                                  : question.explanations[optIndex]
                              ),
                            }}
                          />
                        </Text>
                      ) : null}
                    </li>
                  ))}
                </ul>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(index, question)}
                >
                  Delete
                </Button>
              </>
            )}
          </Card>
        )}
      />
      {/* <Space>
    <Button type="primary" onClick={handleSave}>
      Save
    </Button>
    <Button danger onClick={handleCancel}>
      Cancel
    </Button>
  </Space> */}
    </div>
  );
};

export default ViewQuestion;
