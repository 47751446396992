import { Button, Divider, Form, Input, Radio, Space, Typography } from "antd";
import "katex/dist/katex.min.css";
import React, { useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { showSuccess } from "../../../toastService";
import { renderMath } from "../../../utils/renderMath";

const { TextArea } = Input;
const { Text, Title } = Typography;

interface Props {
  resetMode: () => void;
  unitId : any
}

const NumericalTypeComponent: React.FC<Props> = ({ resetMode,unitId }) => {
  const [form] = Form.useForm();
  const [questionPreview, setQuestionPreview] = useState<string>("");
  const [correctAnswer, setCorrectAnswer] = useState<string>("");
  const [explanation, setExplanation] = useState<string>("");
  const [explanationPreview, setExplanationPreview] = useState<string>("");
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">(
    "easy"
  );
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const handleSubmit = async (values: any) => {
    const questionData = {
      question: values.question,
      imageUrls,
      correctAnswer: correctAnswer.toString(),
      explanation,
      difficulty,
      type: "numerical",
    };
    await axiosInstance.post(
      `/api/admin/v1/unit/create-qeustion/numerical/${unitId}`,
      questionData
    );
    console.log("questionData", questionData);
    showSuccess("Question added successfully!");
    form.resetFields();
    setQuestionPreview("");
    setCorrectAnswer("");
    setExplanation("");
    setExplanationPreview("");
    resetMode();
  };

  const updateImageUrl = (index: number, value: string) => {
    const newImageUrls = [...imageUrls];
    newImageUrls[index] = value;
    setImageUrls(newImageUrls);
  };

  const addImageUrl = () => {
    setImageUrls([...imageUrls, ""]);
  };

  return (
    <div style={{ maxWidth: 600 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Difficulty Level"
          name="difficulty"
          rules={[
            { required: true, message: "Please select the difficulty level!" },
          ]}
        >
          <Radio.Group
            onChange={(e) => setDifficulty(e.target.value)}
            value={difficulty}
          >
            <Radio value="easy">Easy</Radio>
            <Radio value="medium">Medium</Radio>
            <Radio value="hard">Hard</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Question Text (Supports LaTeX)"
          name="question"
          rules={[
            { required: true, message: "Please enter the question text!" },
          ]}
        >
          <TextArea
            rows={3}
            onChange={(e) => setQuestionPreview(e.target.value)}
            placeholder="Enter question text with LaTeX syntax if needed"
          />
        </Form.Item>
        <Divider />
        <Text strong>LaTeX Preview:</Text>
        <div
          style={{ minHeight: 50, padding: "10px 0" }}
          dangerouslySetInnerHTML={{
            __html: renderMath(questionPreview) || "Type to see preview...",
          }}
        />
        <Divider />
        <Title level={5}>Image URLs</Title>
        {imageUrls.map((url, index) => (
          <Space key={index} style={{ marginBottom: 8 }} align="baseline">
            <Input
              placeholder={`Image URL ${index + 1}`}
              value={url}
              onChange={(e) => updateImageUrl(index, e.target.value)}
            />
          </Space>
        ))}
        <Button type="dashed" onClick={addImageUrl}>
          Add Image URL
        </Button>
        <Divider />

        <Title level={5}>Correct Answer (Integer Only)</Title>
        <Input
          type="number"
          placeholder="Enter the correct integer answer"
          value={correctAnswer}
          onChange={(e) => setCorrectAnswer(e.target.value.toString())}
        />
        <Divider />

        <Title level={5}>Explanation</Title>
        <TextArea
          rows={2}
          placeholder="Provide an explanation for the answer"
          value={explanation}
          onChange={(e) => {
            setExplanation(e.target.value);
            setExplanationPreview(e.target.value);
          }}
        />
        <Divider />
        <Text strong>Explanation Preview:</Text>
        <div
          style={{ minHeight: 50, padding: "10px 0" }}
          dangerouslySetInnerHTML={{
            __html: renderMath(explanationPreview) || "Type to see preview...",
          }}
        />
        <Divider />

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NumericalTypeComponent;
